import NextLink from "next/link";
import { Link as BaseLink, type LinkProps as ChakraProps } from "@chakra-ui/react";
import { type VFC } from "react";

type Props = ChakraProps;

/**
 * isExternal といった Chakra 独自の props を変換する関数
 *
 * @param props Chakra の型に基づいた props
 * @returns NextLink に対応した props
 */
export const propsTranslator = (props: ChakraProps): ChakraProps => {
  const { as, isExternal, ...sharedProps } = props;

  const nextProps = {
    ...sharedProps,
    href: props.href ?? "",
  };

  if (isExternal) {
    nextProps.target = "_blank";
    nextProps.rel = "noreferrer";
  }

  return {
    as,
    ...nextProps,
  };
};

export const Link: VFC<Props> = (props) => {
  const { children, ...chakraProps } = props;

  const nextProps = propsTranslator(chakraProps);
  return (
    <BaseLink as={NextLink} {...nextProps}>
      {children}
    </BaseLink>
  );
};
