import NextLink from "next/link";
import { Box, HStack } from "@chakra-ui/react";
import { type VFC } from "react";
import { IconImage } from "@/components/icon/IconImage";
import { socialProfileUrl } from "@/utils/url";
import { Typography } from "@/components/Typography";
import {
  profileCardIntroductionTestId,
  profileCardLinkTestId,
  profileCardNicknameTestId,
} from "@/components/profile/ProfilePane/const";
import { type Maybe, type ProfileSearchResult, type User } from "@/generated/graphql.gen";

// TODO: Fragment collocation が実装される時に型定義を見直す
type UserForProfileCard = Pick<User, "id" | "profilePhotoUrl">;

export interface UserProfileForProfileCard extends Omit<ProfileSearchResult, "user"> {
  user?: Maybe<UserForProfileCard>;
}

export interface ProfileCardProps {
  readonly userProfile: UserProfileForProfileCard;
}

export const ProfileCard: VFC<ProfileCardProps> = (props) => {
  const { userProfile } = props;
  const iconFallbackSrc = socialProfileUrl("images/default-user-profile-pink.png");
  const userName: string = userProfile.username;
  const profileUrl = `${userName}?q=true`;
  return (
    <Box bg="bg.gray.100" cursor="pointer" fontWeight="normal">
      <NextLink href={profileUrl} passHref legacyBehavior>
        <HStack as="a" pl="1rem" pt="1rem" pr="2rem" pb="1rem" data-testid={profileCardLinkTestId}>
          <IconImage
            pcBoxSize="80px"
            spBoxSize="70px"
            src={userProfile.user?.profilePhotoUrl ?? ""}
            fallbackSrc={iconFallbackSrc}
            marginBottom="0px"
            boxShadow="0px"
          />
          <Box w={{ pc: "85%", sp: "80%" }}>
            <Typography
              variant={{ sp: "body3b", pc: "body2b" }}
              letterSpacing="1.6px"
              mb="0.5rem"
              data-testid={profileCardNicknameTestId}
            >
              {userProfile.nickname}
            </Typography>
            <Typography
              variant={{ sp: "body4", tablet: "body4", pc: "body3" }}
              letterSpacing="1.6px"
              noOfLines={3}
              data-testid={profileCardIntroductionTestId}
            >
              {userProfile.introduction}
            </Typography>
          </Box>
        </HStack>
      </NextLink>
    </Box>
  );
};
