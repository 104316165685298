import { Button } from "./Button";
import { Typography } from "@/components/Typography";
import { type ButtonProps } from "@chakra-ui/react";
import { type VFC } from "react";
import token from "@sheinc/design-tokens";

export const ShareButton: VFC<ButtonProps> = (props) => {
  const { onClick, ...rest } = props;
  return (
    <Button
      variant="solid"
      bgColor={token.color.button.primary.bgDefault.value}
      color={token.color.button.primary.textDefault.value}
      type="submit"
      w={{ sp: "82px", pc: "102px" }}
      h={{ sp: "33px", pc: "40px" }}
      onClick={onClick}
      {...rest}
    >
      <Typography variant={{ sp: "body3b", tablet: "body3b", pc: "body2b" }} letterSpacing="1.6px">
        シェア
      </Typography>
    </Button>
  );
};
