import { type NextPage } from "next";
import { ProfileCard } from "@/components/profile/ProfilePane/ProfileCard";
import { Box, Center, SimpleGrid } from "@chakra-ui/react";
import { CommonHeader } from "@/components/Header/CommonHeader";
import { useCurrentProfile } from "@/pages/_app.page";
import { Typography } from "@/components/Typography";
import { TopBanner } from "@/components/TopBanner";
import { SpinnerRow } from "@/components/Spinner/SpinnerRow";
import { useSearchResultProfilesQuery } from "@/generated/graphql.gen";
import { useGraphQLError } from "@/hooks/useGraphQLError";

const TopPage: NextPage = () => {
  const currentProfile = useCurrentProfile();

  const { onError } = useGraphQLError();
  // TODO: 本当は新着順に取得するAPIを呼びたいが、API側を作る余裕がなさそうなのでいったんコレで
  const { data, loading } = useSearchResultProfilesQuery({
    variables: { data: { tagIds: [], first: 6 } },
    onError,
  });

  // ロード中はスピナーを表示する
  if (!currentProfile.initialized || loading) {
    return <SpinnerRow />;
  }

  // TOPページをを閲覧できるのは会員のみ
  if (currentProfile.user === null) {
    return null;
  }

  const profilePhotoUrl = currentProfile.user.profilePhotoUrl ?? "";
  return (
    <>
      <CommonHeader profilePhotoUrl={profilePhotoUrl} />
      <Center>
        <Box
          mx={{ sp: "1rem", pc: "9.375rem" }}
          mt={{ sp: "2rem", pc: "2rem" }}
          w={{ sp: "90%", pc: "1021px" }}
          mb={{ sp: "6rem", pc: "10rem" }}
        >
          <Box mb={{ sp: "1rem", pc: "2rem" }}>
            <TopBanner profile={currentProfile.user.profile} />
          </Box>
          <Box letterSpacing="2px" mb={{ sp: "1rem", pc: "0.7rem" }}>
            <Typography variant={{ sp: "body1b", pc: "heading4b" }} color="theme.primary.main">
              プロフィール一覧
            </Typography>
          </Box>
          <Box letterSpacing="2px" mb="1.5rem">
            <Typography variant={{ sp: "body3b", pc: "body1b" }}>新着プロフィール</Typography>
          </Box>
          <SimpleGrid columns={{ sp: 1, pc: 2 }} spacing="1rem">
            {data?.searchResultProfiles?.edges.map((userProfile) => (
              <ProfileCard userProfile={userProfile.node} key={userProfile.cursor} />
            ))}
          </SimpleGrid>
        </Box>
      </Center>
    </>
  );
};

export default TopPage;
