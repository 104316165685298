import { type VFC } from "react";
import { Image } from "@chakra-ui/react";
import { iconImageTestId } from "@/components/icon/const";

interface IconImageProps {
  readonly pcBoxSize?: string;
  readonly spBoxSize?: string;
  readonly marginBottom?: string;
  readonly src: string;
  readonly fallbackSrc: string;
  readonly boxShadow?: string;
  readonly pcBorder?: string;
  readonly spBorder?: string;
}

export const IconImage: VFC<IconImageProps> = ({
  pcBoxSize = "186px",
  spBoxSize = "107px",
  marginBottom = "19px",
  src,
  fallbackSrc,
  boxShadow = "2px 2px 12px rgba(0, 0, 0, 0.08)",
  pcBorder = "4px solid white",
  spBorder = "3px solid white",
}) => {
  return (
    <Image
      data-testid={iconImageTestId}
      boxSize={{ sp: spBoxSize, pc: pcBoxSize }}
      objectFit="cover"
      mb={marginBottom}
      src={src}
      fallbackSrc={fallbackSrc}
      border={{ sp: spBorder, pc: pcBorder }}
      borderRadius="50%"
      boxShadow={boxShadow}
      alt="profile_image"
      bgColor="white"
    />
  );
};
