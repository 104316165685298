import { type VFC } from "react";
import { Box, Image } from "@chakra-ui/react";
import { socialProfileUrl } from "@/utils/url";

interface ButtonProps {
  readonly src?: string;
  readonly onClick: (() => Promise<boolean>) | (() => void);
}

export const SearchButton: VFC<ButtonProps> = ({ src = "images/search.svg", onClick }) => {
  return (
    <Box
      as="button"
      w={{ sp: "32px", pc: "32px" }}
      h={{ sp: "32px", pc: "32px" }}
      onClick={onClick}
      title="プロフィールを検索"
    >
      <Image src={socialProfileUrl(src)} alt="search_icon" />
    </Box>
  );
};
