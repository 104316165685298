import { Link } from "@/components/Link";
import { IconImage } from "@/components/icon/IconImage";
import { socialProfileUrl } from "@/utils/url";
import { Flex, HStack, Image, Spacer } from "@chakra-ui/react";
import NextLink from "next/link";
import { type VFC } from "react";

interface CommonHeaderProps {
  readonly profilePhotoUrl: string;
}

export const CommonHeader: VFC<CommonHeaderProps> = (props) => {
  const { profilePhotoUrl } = props;
  const iconFallbackSrc = socialProfileUrl("images/default-user-profile.png");

  return (
    <>
      <Flex>
        <Link href="/">
          <Image
            src="/images/sheprofile-logo.svg"
            alt="social-profile-logo"
            w="8.1rem"
            h="1.8rem"
            ml={{ sp: "1rem", pc: "2.125rem" }}
            mt={{ sp: "1.2rem", pc: "1rem" }}
            cursor="pointer"
          />
        </Link>
        <Spacer />

        <HStack mr="0.875rem" mt="0.8rem">
          <Link href="/me" cursor="pointer">
            <IconImage
              src={profilePhotoUrl}
              fallbackSrc={iconFallbackSrc}
              pcBoxSize="32px"
              spBoxSize="40px"
              marginBottom="0px"
              boxShadow="0px"
              pcBorder="0px"
              spBorder="0px"
            />
          </Link>
        </HStack>
      </Flex>
    </>
  );
};
