import { type ErrorContextValue } from "@/components/Errors";
import { useError } from "@/hooks/useError";
import { ApolloError } from "@apollo/client";
import { useCallback } from "react";

type OnErrorFn = (error: unknown, option?: { fallbackMessage?: string }) => void;

export const useGraphQLError = (): ErrorContextValue & {
  onError: OnErrorFn;
} => {
  const context = useError();

  const onError: OnErrorFn = useCallback(
    (error, option = {}): void => {
      if (error instanceof ApolloError) {
        if (error.networkError) {
          context.handleError({ message: "ネットワーク回線を確認してください。" });
          return;
        }
        // バックエンドでUI向けにメッセージを入れている場合はそれを表示する
        if (error.graphQLErrors.length > 0 && error.graphQLErrors[0].extensions?.message) {
          context.handleError({ message: error.graphQLErrors[0].extensions.message as string });
          return;
        }
      }

      context.handleError({
        message: option.fallbackMessage ?? "エラーが発生しました。しばらくしてから再度お試しください。",
      });
    },
    [context],
  );

  return { ...context, onError, handleError: onError };
};
