import { type FC } from "react";
import { Button as ChakraButton, type ButtonProps } from "@chakra-ui/react";

export const Button: FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <ChakraButton borderRadius="7rem" {...rest}>
      {children}
    </ChakraButton>
  );
};
