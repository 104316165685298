import { CreateProfileButton } from "@/components/TopBanner/CreateProfileButton";
import { type Maybe, type UserProfile } from "@/generated/graphql.gen";
import { Box } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { type VFC } from "react";
import { topBannerCreateProfileButtonTestId } from "./const";

interface TopBannerProps {
  profile: Maybe<UserProfile> | undefined;
}

export const TopBanner: VFC<TopBannerProps> = (props) => {
  const { profile } = props;
  const router = useRouter();
  return (
    <>
      {!profile && (
        <Box position="relative">
          <Box position="absolute" top={{ sp: "3.8rem", pc: "3rem" }} right={{ sp: "0rem", pc: "2rem" }}>
            <CreateProfileButton
              onClick={async () => await router.push("/me")}
              data-testid={topBannerCreateProfileButtonTestId}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
