import { Button } from "@/components/Button";
import { Typography } from "@/components/Typography";
import { type ButtonProps } from "@chakra-ui/react";
import { type VFC } from "react";

export const CreateProfileButton: VFC<ButtonProps> = (props) => {
  const { onClick, ...rest } = props;
  return (
    <Button
      variant="solid"
      bgColor="theme.primary.main"
      color="white"
      type="submit"
      mr="1.5rem"
      w="143px"
      h="36px"
      onClick={onClick}
      {...rest}
    >
      <Typography variant="body4b" letterSpacing="1.6px">
        SHEプロフを作る
      </Typography>
    </Button>
  );
};
